import removeaccents from 'remove-accents';

function arraySplitBySize(array, size) {
  const result = [];
  while (array.length) {
    result.push(array.splice(0, size));
  }
  return result;
}
function arrayFindClosestValue(array, value) {
  const result = array.reduce((a, b) => {
    return Math.abs(b - value) < Math.abs(a - value) ? b : a;
  });
  return result;
}
function arraySortBy(key) {
  return (a, b) => a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
}
function arrayRemoveDuplicates(array) {
  return [...new Set(array)];
}

const isEmpty = (value) => {
  if (value === null || value === void 0 || value === "") return true;
  return !!(Array.isArray(value) && value.length === 0);
};
const isNullOrUndefined = (value) => {
  return value === null || value === void 0;
};
const isEmptyArray = (arr) => {
  return Array.isArray(arr) && arr.length === 0;
};
const isObject = (obj) => obj !== null && !!obj && typeof obj === "object" && !Array.isArray(obj);
const isToday = (date) => {
  const today = /* @__PURE__ */ new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
  );
};

function strLimitLength(str, limit) {
  if (str.length > limit) return str.substring(0, limit) + "...";
  return str;
}
function strConvertToString(v) {
  if (typeof v === "number" || typeof v === "boolean") return v.toString();
  return v ? v : "";
}
function strUppercaseFirstLetter(string) {
  return string.charAt(0).toUpperCase().concat(string.slice(1));
}
function strUppercaseFirstLetterForEachWords(string) {
  const words = string.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(" ");
}
function removePunctuation(text) {
  let noPunctuation = text.replace(/[«»!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, " ");
  noPunctuation = noPunctuation.replace(/\n/g, " ");
  return noPunctuation;
}
function removeAccents(text) {
  return removeaccents(text);
}
function keepOnlyTextAndNumber(text) {
  return text.replace(/[^a-zA-Z0-9\s]/g, "");
}
function removeDuplicates(text) {
  const words = text.split(/\s+/);
  const uniqueWords = /* @__PURE__ */ new Set();
  const uniqueWordArray = words.filter((word) => {
    if (!uniqueWords.has(word)) {
      uniqueWords.add(word);
      return true;
    }
    return false;
  });
  return uniqueWordArray.join(" ");
}
function removeMultipleSpaces(text) {
  return text.replace(/\s+/g, " ");
}
function formatDateYMD(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
function formatToTwoDigits(num) {
  return num < 10 ? `0${num}` : `${num}`;
}
function formatNewlineToHtml(str) {
  return str.replace(/\n/g, "<br />");
}

const removeHtmlLinks = (content) => {
  const regex = new RegExp("https?:\\/\\/.*?[\\s+]", "gm");
  return content.replace(regex, "").trim();
};
const avatarText = (value) => {
  if (!value) return "";
  const nameArray = value.split(" ");
  return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
};
const kFormatter = (num) => {
  const regex = /\B(?=(\d{3})+(?!\d))/g;
  return Math.abs(num) > 9999 ? `${Math.sign(num) * +(Math.abs(num) / 1e3).toFixed(1)}k` : Math.abs(num).toFixed(0).replace(regex, ",");
};
const formatDate = (value, formatting = { month: "short", day: "numeric", year: "numeric" }) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};
function formatVideoTimeInSeconds(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds % 3600 / 60);
  const remainingSeconds = seconds % 60;
  if (hours === 0) return `${formatToTwoDigits(minutes)}:${formatToTwoDigits(remainingSeconds)}`;
  return `${hours}:${formatToTwoDigits(minutes)}:${formatToTwoDigits(remainingSeconds)}`;
}
const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };
  if (toTimeForCurrentDay && isToday(date)) formatting = { hour: "numeric", minute: "numeric" };
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

function round$1(num, precision) {
  const modifier = 10 ** precision;
  return Math.round(num * modifier) / modifier;
}
function sizeToKb(size) {
  return Math.round(size / 1024);
}
function sizeToMb(size) {
  return Math.round(size / (1024 * 1024));
}
function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function getFirstMatchV2(text, regExp) {
  const [[, group1]] = [...text.matchAll(regExp)];
  return group1;
}
function getFirstMatch(text, regExp) {
  return getFirstMatchV2(text, regExp);
}
function escapeStringRegexp(string) {
  if (typeof string !== "string") {
    throw new TypeError("Expected a string");
  }
  return string.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
}

function round(num, precision) {
  const modifier = 10 ** precision;
  return Math.round(num * modifier) / modifier;
}
function getMemoryUsageInMB(valueInBytes) {
  const gbNow = valueInBytes / 1024 / 1024;
  return round(gbNow, 2);
}
function getNodeMemoryUsageInMB() {
  const mu = process.memoryUsage();
  const r = {};
  for (const [key, value] of Object.entries(mu)) {
    r[key] = getMemoryUsageInMB(value);
  }
  return r;
}
async function waitToEmulateLoading() {
  await sleep(1e3);
}
function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

function normalize(strArray) {
  const resultArray = [];
  if (strArray.length === 0) {
    return "";
  }
  if (typeof strArray[0] !== "string") {
    throw new TypeError("Url must be a string. Received " + strArray[0]);
  }
  if (strArray[0].match(/^[^/:]+:\/*$/) && strArray.length > 1) {
    const first = strArray.shift();
    strArray[0] = first + strArray[0];
  }
  if (strArray[0].match(/^file:\/\/\//)) {
    strArray[0] = strArray[0].replace(/^([^/:]+):\/*/, "$1:///");
  } else {
    strArray[0] = strArray[0].replace(/^([^/:]+):\/*/, "$1://");
  }
  for (let i = 0; i < strArray.length; i++) {
    let component = strArray[i];
    if (typeof component !== "string") {
      throw new TypeError("Url must be a string. Received " + component);
    }
    if (component === "") {
      continue;
    }
    if (i > 0) {
      component = component.replace(/^[/]+/, "");
    }
    if (i < strArray.length - 1) {
      component = component.replace(/[/]+$/, "");
    } else {
      component = component.replace(/[/]+$/, "/");
    }
    resultArray.push(component);
  }
  let str = resultArray.join("/");
  str = str.replace(/\/(\?|&|#[^!])/g, "$1");
  const parts = str.split("?");
  str = parts.shift() + (parts.length > 0 ? "?" : "") + parts.join("&");
  return str;
}
function urlJoin(...args) {
  let input;
  if (typeof args[0] === "object") {
    input = args[0];
  } else {
    input = [].slice.call(args);
  }
  return normalize(input);
}

export { arrayFindClosestValue, arrayRemoveDuplicates, arraySortBy, arraySplitBySize, avatarText, escapeStringRegexp, formatDate, formatDateToMonthShort, formatDateYMD, formatNewlineToHtml, formatToTwoDigits, formatVideoTimeInSeconds, getFirstMatch, getNodeMemoryUsageInMB, isEmpty, isEmptyArray, isNullOrUndefined, isObject, isToday, kFormatter, keepOnlyTextAndNumber, randomIntFromInterval, removeAccents, removeDuplicates, removeHtmlLinks, removeMultipleSpaces, removePunctuation, round$1 as round, sizeToKb, sizeToMb, sleep, strConvertToString, strLimitLength, strUppercaseFirstLetter, strUppercaseFirstLetterForEachWords, urlJoin, waitToEmulateLoading };
